<template>
	<tr v-if="$vuetify.breakpoint.smAndUp">
		<td :class="['px-1']" v-for="(column, index) in headers" :key="index">
			<div v-if="column.value == 'data-table-select'" class="text-center">
				<v-btn
					icon
					outlined
					color="primary"
					ref="refreshButton"
					@click.stop="reset"
					:disabled="!Object.values(filters).some((i) => !!i)"
				>
					<v-icon>mdi-filter-off</v-icon>
				</v-btn>
			</div>
			<div v-if="column.filterable == false"></div>
			<v-select
				v-else-if="column.dataType == 'select'"
				v-model="filters[column.value]"
				:items="
					items
						.map((c) => c[column.value])
						.filter((v) => !!v)
						.sort()
				"
				:label="column.text"
				multiple
				dense
				single-line
				hide-details
				class="hide-line"
			>
				<template v-slot:prepend-item>
					<v-list-item ripple @click="toggle(column.value)">
						<v-list-item-action>
							<v-icon
								:color="
									filters[column.value] && filters[column.value].length > 0
										? 'secondary'
										: ''
								"
								v-text="getSelectAllIcon(column.value)"
							></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Seleccionar todo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="mt-2"></v-divider>
				</template>
				<template v-slot:selection="{ attrs, item, selected, parent }">
					<v-chip
						class="mr-1 mb-1"
						v-bind="attrs"
						:input-value="selected"
						:value="item"
						small
						close
						@click:close="parent.selectItem(item)"
					>{{ item }}</v-chip>
				</template>
			</v-select>
			<v-text-field
				v-else-if="column.dataType == 'text' || column.dataType == 'number'"
				v-model="filters[column.value]"
				type="text"
				:placeholder="column.text"
				dense
				hide-details
				single-line
				class="hide-line"
			/>
			<v-menu
				v-else-if="column.dataType == 'date'"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						readonly
						v-bind="attrs"
						v-on="on"
						v-model="filters[column.value]"
						:placeholder="column.text"
						dense
						hide-details
						clearable
						class="hide-line"
					/>
				</template>
				<v-date-picker v-model="filters[column.value]" no-title scrollable dense range>
					<v-spacer />
					<v-btn small text color="primary" @click="clearKey(column.value)">Borrar</v-btn>
				</v-date-picker>
			</v-menu>

			<v-btn-toggle v-else-if="column.dataType == 'bool'" borderless group v-model="filters[column.value]">
				<v-btn small :value="false">No</v-btn>
				<v-btn small :value="true">Sí</v-btn>
			</v-btn-toggle>
		</td>
	</tr>
	<div v-else>
		<portal to="filter-btn">
			<v-btn fixed right small color="secondary" @click="sheet = !sheet" fab style="z-index: 100; top: calc(100vh - 100px);" >
				<v-icon>mdi-filter</v-icon>
			</v-btn>
		</portal>
		<v-bottom-sheet scrollable v-model="sheet">
			<v-card v-touch="{ down: () => closeFilters() }">
				<v-toolbar dense color="secondary">
					<v-btn icon @click.stop="sheet = false">
						<v-icon color="white">mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title class="white--text">Filtros</v-toolbar-title>
					<v-spacer />
					<v-btn icon @click.stop="filters = {}">
						<v-icon color="white">mdi-reload</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text ref="scroll">
					<div class="my-4" v-for="(column, index) in headers" :key="index">
						<template
							v-if="
								column.dataType == 'select' &&
								items.some((c) => !!c[column.value])
							"
						>
							<v-subheader v-text="column.text"></v-subheader>
							<v-divider />
							<div class="d-flex flex-wrap">
								<div
									style="flex-basis: 50%"
									v-for="(item, index) in Array.from(
										new Set(items.map((c) => c[column.value]))
									).sort()"
									:key="index"
								>
									<v-checkbox
										class="mt-0"
										multiple
										dense
										hide-details
										v-model="filters[column.value]"
										:value="item"
										:label="item"
										color="secondary"
									/>
								</div>
							</div>
						</template>
						<v-text-field
							v-else-if="
								column.filterable != false &&
								column.sortable != false &&
								column.dataType == 'text'
							"
							v-model="filters[column.value]"
							type="text"
							:placeholder="column.text"
							dense
							hide-details
							full-width
							filled
						/>
						<v-menu
							v-else-if="column.dataType == 'date'"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									readonly
									v-bind="attrs"
									v-on="on"
									v-model="filters[column.value]"
									:placeholder="column.text"
									dense
									hide-details
									filled
									append-icon="mdi-calendar"
									clearable
									clear-icon="mdi-close"
								/>
							</template>
							<v-date-picker v-model="filters[column.value]" no-title scrollable dense range>
								<v-spacer />
								<v-btn small text color="primary" @click.stop="clearKey(column.value)">Borrar</v-btn>
							</v-date-picker>
						</v-menu>
					</div>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		headers: Array,
		items: Array,
		value: Object,
	},
	data() {
		return {
			filters: { ...this.value },
			unwatch: null,
			sheet: false,
			timeout: null,
		};
	},
	methods: {
		closeFilters() {
			if (this.$refs.scroll.scrollTop == 0) this.sheet = false;
		},
		clearKey(key) {
			this.filters[key] = null;
		},
		reset() {
			this.filters = {};
		},
		toggle(key) {
			const items = [
				...new Set(this.items.map((c) => c[key]).filter((v) => !!v)),
			];

			if (
				this.filters[key] == null ||
				this.filters[key] == undefined ||
				this.filters[key].length < items.length
			) {
				this.$set(this.filters, key, items);
			} else if (this.filters[key].length == items.length)
				this.filters[key] = null;
		},
		getSelectAllIcon(key) {
			if (!this.filters[key]) return "mdi-checkbox-blank-outline";

			const itemsL = [
				...new Set(this.items.map((c) => c[key]).filter((v) => !!v)),
			].length;

			if (this.filters[key].length == itemsL) return "mdi-close-box";
			return "mdi-minus-box";
		},
	},
	created() {
		this.unwatch = this.$watch(
			"filters",
			() => {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					let temp = Object.fromEntries(
						Object.entries(this.filters).filter(([_, v]) => v != null)
					);
					this.$emit("input", temp);
				}, 500);
			},
			{ deep: true }
		);
	},
	beforeDestroy() {
		this.unwatch();
	},
};
</script>

<style>
.hide-checkbox .v-input--selection-controls__input {
	display: none;
}
.hide-line .v-input__slot::before {
	display: none;
}
.hide-line .v-text-field__slot::before {
	display: none;
}
.v-btn-toggle--group > .v-btn.v-btn {
	margin: 0px !important;
}
</style>